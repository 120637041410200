"use client"

import LocationSelector from "src/components/locationselector/LocationSelector";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { gql } from "graphql-request";
import getURLParameter from "src/lib/client/getURLParameter";
import { useFilminfo } from "src/lib/client/useFilminfo";
import { NextRouter, useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

export type LocationContextType = {
	location: string | null;
	setLocation: (location: string) => void;
	showLocationDialog: boolean;
	setShowLocationDialog: (show: boolean) => void;
}

const LocationContext = createContext<LocationContextType>({
	location: null,
	setLocation: (location: string) => { },
	showLocationDialog: false,
	setShowLocationDialog: (show: boolean) => { }
});

const ASK_FOR_LOCATION = [
	/^\/film\/.*/i,
	/^\/program\/.*/i
];

//#region [Props]
type LocationWrapperProps = {
	children: React.ReactNode;
	locationSelector?: React.ReactNode; // if you want to have a custom locationselector
};
//#endregion

//#region [Component]
export function LocationWrapper({ children, locationSelector }: LocationWrapperProps) {
	const router = useRouter();
	const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

	const [showLocationDialog, setShowLocationDialog] = useState(false);

	const { fiLoading, fiData } = useFilminfo(searchQuery, { searchText: selectedLocation }, { active: !!selectedLocation });

	useEffect(() => {
		// Should only be called initially.
		let locationParam = getURLParameter("location") as string | null;
		if (locationParam) {
			locationParam = decodeURIComponent(locationParam);
			setSelectedLocation(locationParam);
		} else {
			const locCookie = getCookie("location");
			if (locCookie) {
				setSelectedLocation(locCookie);
			} else {
				setSelectedLocation("");
			}
		}
	}, []);

	useEffect(() => {
		// when selected location changes, update the cookie and the url
		if (selectedLocation) {
			setCookie("location", selectedLocation, { domain: ".filmweb.no", path: '/', maxAge: 31556926 }); // 31 556 926 == 1 year
			updateUrl(selectedLocation, router);
		}
		// NOTE: We cannot have router in deps, because that will cause infinite loop on pages where location is relevant
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLocation]);

	useEffect(() => {
		if (!fiLoading && fiData) {
			const locs = fiData?.cinemaQuery?.searchForLocations;
			const matches = locs?.filter(match => selectedLocation && match!.name.toLowerCase() === selectedLocation.toLocaleLowerCase());
			if ((matches?.length ?? 0) > 0) {
				setShowLocationDialog(false);
			} else {
				setSelectedLocation(null);
				deleteCookie("location", { domain: ".filmweb.no", path: '/' });
			}
		}
	}, [fiLoading, fiData, selectedLocation]);

	return (<LocationContext.Provider value={{
		location: selectedLocation,
		setLocation: setSelectedLocation,
		showLocationDialog: showLocationDialog,
		setShowLocationDialog: setShowLocationDialog
	}}>
		{children}
		{showLocationDialog && <>
			{locationSelector && <>{locationSelector}</>}
			{!locationSelector && <LocationSelector />}
		</>}
	</LocationContext.Provider>);
}
//#endregion

//#region [Other]
export function useLocationContext() {
	return useContext(LocationContext);
}

export function useCinemaLocation() {
	const locCookie = getCookie("location");
	if (locCookie) {
		return locCookie as string;
	}
	return null;
}
//#endregion

//#region [Other]
function updateUrl(loc: string, router: NextRouter) {
	if (typeof window !== "undefined") {
		if (ASK_FOR_LOCATION.filter(re => re.test(window.location.pathname)).length === 0) {
			// don't update the url if we are not on page that actually needs it
			return;
		}
		const search = window.location.search;
		let newSearch = "?";
		if (search) {
			const re = /(?:location=.*?(&)|location=.*$)/i;
			if (re.test(search)) {
				newSearch = search.replace(re, `location=${loc}$1`);
			} else {
				newSearch = search + "&location=" + loc;
			}

		} else {
			newSearch += "location=" + loc;
		}
		let newUrl = window.location.pathname + newSearch;
		if (window.location.hash) {
			newUrl += window.location.hash;
		}
		if (router.isReady) {
			router.replace(newUrl, newUrl, { scroll: false, shallow: true }).catch(e => {
				// workaround https://github.com/vercel/next.js/issues/37362#issuecomment-1283671326
				if (!e.cancelled) {
					throw e;
				}
			});
		}
	}
}
//#endregion

//#region [Other]
const searchQuery = gql`query ($searchText: String) {
	cinemaQuery {
		searchForLocations (searchText: $searchText) {
			name
		}
	}
}`;
//#endregion