"use client"
import axios, { isAxiosError } from "axios";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { useDebugValue, useEffect, useRef, useState } from "react";
import { ISearchResult } from "src/lib/types/search";

type AzUseSuggestOptions = {
    active?: boolean;
    inputDelay?: number;
}

export function useAzSuggest(searchText?: string, opts?: AzUseSuggestOptions) {
    const { active = true, inputDelay = 400 } = opts ?? {};
    useDebugValue(searchText, searchText => `searchText: ${searchText}`);
    useDebugValue(active, active => `active: ${active}`);
    useDebugValue(inputDelay, inputDelay => `inputDelay: ${inputDelay}`);
    const [_searchText, setSearchText] = useState("");

    const { data, isFetched, isFetching } = useQuery<ISearchResult[]>({
        queryKey: ["azsuggest", _searchText?.trim()],
        enabled: active && !!_searchText?.trim(),
        queryFn: azQFn,
        refetchOnWindowFocus: false
    });

    const searchTimer = useRef<any>(null);

    useEffect(() => {
        // make sure a new search is not triggered until the input delay has fired
        if (searchText?.trim() && searchText.trim() !== _searchText) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            if (inputDelay > 0) {
                searchTimer.current = setTimeout(() => {
                    setSearchText(searchText.trim());
                }, inputDelay);
            } else {
                setSearchText(searchText.trim());
            }
        } else if (!searchText?.trim()) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
            setSearchText("");
        }
        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
                searchTimer.current = null;
            }
        }
    }, [searchText, _searchText, inputDelay]);

    return {
        searchLoading: isFetching,
        searchLoaded: isFetched,
        searchResult: data
    };
}

const azQFn: QueryFunction<ISearchResult[]> = async (context) => {
    const { signal } = context;
    const searchTerm = context.queryKey[1];
    const axiosResponse = await axios.request<ISearchResult[]>({ url: "https://search.filmweb.no/api/Suggest", method: "GET", params: { q: searchTerm }, signal });
    if (isAxiosError(axiosResponse)) {
        throw axiosResponse;
    }
    return axiosResponse.data.map(d => ({ ...d, __typename: "ISearchResult" }));
}