"use client"
import SearchSvg from 'src/components/svg/SearchSvg';
import { NextRouter, useRouter } from 'next/router';
import { ChangeEvent, Dispatch, useEffect, useRef } from 'react';
import styled, { css } from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
import { ISearchAction } from 'src/components/search/ISearchAction';
import LoadingIndicator from 'src/components/loadingindicator/LoadingIndicator';

//#region [Props]
type SearchProps = {
	showSearchResults: boolean;
	dispatch: Dispatch<ISearchAction>;
	searchExpression: string;
	className?: string;
	placeholderText?: string;
	isSearching?: boolean; // only use this id you with to use the search button as a loading indicator
	disabled?: boolean;
	controlled?: boolean;
	disableEnter?: boolean;
	useIconGradient?: boolean;
};
//#endregion

//#region [Component]
export default function Search({ showSearchResults, dispatch, searchExpression, className, placeholderText, isSearching, disabled = false, controlled = false, disableEnter = false, useIconGradient = false }: SearchProps) {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const router = useRouter();
	const phText = (placeholderText ?? (showSearchResults ? "Skriv inn søk" : "Søk"));

	useEffect(() => {
		// instead of a controlled component
		if (!controlled && !searchExpression && inputRef.current) {
			inputRef.current.value = "";
		}

	}, [searchExpression, controlled])

	return <SSearch className={className}>
		<SSearchInput
			className="searchInput"
			type="text"
			placeholder={phText} name="search"
			onKeyUp={event => checkFullSearch(dispatch, event, router, disableEnter)}
			onFocus={() => dispatch({ type: "BEGIN_SEARCH" })}
			autoComplete="off"
			ref={inputRef}
			value={controlled ? searchExpression : undefined}
			onChange={controlled ? (event) => dispatch({ type: "DO_SEARCH", searchExpression: event.currentTarget.value ?? "" }) : undefined}
		/>
		<SSearchButton className="searchButton"
			disabled={disabled}
			onClick={event => searchIconClick(dispatch, showSearchResults, router, searchExpression)}
		>
			{!isSearching && <SSearchSvg $useIconGradient={useIconGradient} useCustomGradient={useIconGradient}/>}
			{isSearching === true && <LoadingIndicator position='InlineCenter' size='small' />}
		</SSearchButton>
	</SSearch>;
}
//#endregion

//#region [Styles]
const SSearch = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	flex: 1 1 auto;
	max-width: 450px;
	//margin-left: auto;

	@media ${theme.mq.desktop} {
		padding-left: 20px;
	}
`;

const SSearchInput = styled.input`
	background-color: ${theme.palette.lillaMørkHovedfarge};

	color: ${theme.textColor};
	border: none;
	height: 39px;
	border-radius: 20px 0 0 20px;
	padding: 5px 5px 5px 15px;
	min-width: 49px;
	width: 100%;
	flex: 1 1 auto;
	font-size: 16px;

	&:focus {
		color: ${theme.textColor};
	}

	&::placeholder {
		color: ${theme.textColor};
		opacity: 1;
		font-weight: 700;
	}
`;

const SSearchButton = styled.button`
	background-color: ${theme.palette.lillaMørkHovedfarge};
	border: none;
	height: 39px;
	border-radius: 0 20px 20px 0;
	width: 50px;
	color: ${theme.textColor};
	padding: 5px 15px 5px 4px;
	text-align: right;
	flex: 0 0 auto;
`;

type SSearchSvgProps = {
	$useIconGradient: boolean;
}

const SSearchSvg = styled(SearchSvg)<SSearchSvgProps>`
	max-height: 20px;
	margin-top: 4px;
	${props => !props.$useIconGradient ? css`
		path {
			fill: ${theme.textColor};
		}
	`: ""}
`;
//#endregion

//#region [Other] searchReducer
function checkFullSearch(dispatch: Dispatch<ISearchAction>, event: React.KeyboardEvent<HTMLInputElement>, router: NextRouter, disableEnter: boolean) {
	let key = event.key ?? event.keyCode;
	let val = ((event.target as HTMLInputElement)?.value ?? "");
	if( key === "Escape") {
		dispatch({ type: "CLEAR_SEARCH" });
	} else if (disableEnter === false && key === "Enter" && val?.trim()) {
		goToFullSearch(dispatch, router, val);
	} else {
		dispatch({ type: "DO_SEARCH", searchExpression: val });
	}
}

function goToFullSearch(dispatch: Dispatch<ISearchAction>, router: NextRouter, searchValue: string) {
	dispatch({ type: "CLEAR_SEARCH" });
	router.push(`/sok/?q=${encodeURIComponent(searchValue.trim())}&s=${(new Date().getTime())}`);
}

function searchIconClick(dispatch: Dispatch<ISearchAction>, showSearchResults: boolean, router: NextRouter, searchExpression: string) {
	if (showSearchResults) {
		goToFullSearch(dispatch, router, searchExpression);
	} else {
		dispatch({ type: "BEGIN_SEARCH" });
	}
}
//#endregion