import LocationResultList from 'src/components/locationresultlist/LocationResultList';
import ResultPosterRow from 'src/components/search/ResultPosterRow';
import SuspenseFallback from 'src/components/suspensefallback/SuspenseFallback';
import useSearch from 'src/lib/client/useSearch';
import { WatchableContentType } from 'src/lib/movieinfo/movieinfotypes';
import { rgba } from 'polished';
import { useCallback } from 'react';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
import { useRouter } from "next/router";
import { useAzSuggest } from "src/lib/client/useAzSuggest";
//#region [Styles]
const SSearchResult = styled.div`
	position: absolute;
	top: 55px;
	left: 0;
	right: 0;
	//transform: scaleY(1);
	//transform-origin: top;
	background-color: ${rgba(theme.mainColor, theme.generalAlpha)};
	color: ${theme.textColor};
	z-index: 101;
	overflow: hidden;
	transition: transform 0.2s ease-in-out;
`;

const SSearchResultsList = styled.div`
	width: 100%;
	max-width: ${theme.maxContentWidth}px;
	padding: 0 20px;
	margin: 0 auto;

`;

const SSearchResultsEmpty = styled.div`
	min-height: 50px;
	padding: 20px;
	max-width: 650px;
	margin: auto;
	text-align: center;
`;

//#endregion

//#region [Props]
type SearcResultProps = {
    searchExpression?: string;
    showSearchResult: boolean;
};
//#endregion
const noOfMoviesToShow = 6;

//#region [Component]
export default function SearchResult({ searchExpression, showSearchResult }: SearcResultProps) {
    const router = useRouter();

    const isExperimental = router.pathname.indexOf("/experimental") !== -1;

    const { searchLoading: legacySearchLoading, searchResult: legacySearchResult, locationResult } = useSearch(searchExpression, { active: !isExperimental && !!searchExpression?.trim(), maxNumItems: noOfMoviesToShow + 1 });
    const { searchLoading: azSearchLoading, searchResult: azSearchResult } = useAzSuggest(searchExpression, { active: isExperimental && !!searchExpression?.trim() });


    const hasSearchExpression = !!searchExpression?.trim();

    const _trackLocSearch = useCallback((locName: string) => trackSearch(locName, "Kinosted", searchExpression!), [searchExpression]);
    const _trackSearch = useCallback((id: string | number, title: string) => trackSearch(id, title, searchExpression!), [searchExpression]);

    const searchLoading = legacySearchLoading || azSearchLoading;

    return <SSearchResult>
        {showSearchResult && hasSearchExpression && <>
            {searchLoading && <SSearchResultsEmpty>
                <SuspenseFallback text='Henter resultater...' />
            </SSearchResultsEmpty>}

            {!isExperimental && !searchLoading && legacySearchResult.length === 0 && locationResult!.length === 0 && <SSearchResultsEmpty>
                Ingen treff
            </SSearchResultsEmpty>}

            {!searchLoading && ((legacySearchResult?.length ?? 0) > 0 || (locationResult?.length ?? 0) > 0 || (azSearchResult?.length ?? 0) > 0) && <SSearchResultsList>
                {!isExperimental && (locationResult?.length ?? 0) > 0 && <LocationResultList locationList={locationResult!} clickTracker={_trackLocSearch} />}
                {((legacySearchResult?.length ?? 0) > 0 || (azSearchResult?.length ?? 0) > 0) && <ResultPosterRow searchResult={isExperimental ? azSearchResult! : legacySearchResult!} maxResults={noOfMoviesToShow} clickTracker={_trackSearch} experimental={isExperimental} />}
            </SSearchResultsList>}
        </>}
    </SSearchResult>;
}
//#endregion

//#region [Other] trackSearch
function trackSearch(id: string | number, title: string, searchExpression: string) {
    if (typeof window !== "undefined" && searchExpression?.trim().length > 0) {
        window!.dataLayer?.push({
            'event': 'fw_Quicksearch',
            'eventCategory': 'Hurtigsøk',
            'eventAction': `${title} | ${id}`,
            'eventLabel': searchExpression
        });
    }
}
//#endregion